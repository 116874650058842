import gql from 'graphql-tag'
import { Mutation, MutationCreateInquiryRequestArgs } from '../../generated/nest-graphql'
import { ScheduleServiceValues } from './ScheduleServiceMultiStep/ScheduleServiceFormValues'
import queryString from 'query-string'
import { newBackendClient } from '../clients/new-backend.client'
import Cookies from 'js-cookie'
import { getConvertExperimentsFromCookies } from '../../lib/convert'

export const createInquiryRequest = async (values: Omit<ScheduleServiceValues, 'firstName' | 'lastName'>) => {
  const campaignUrl = Cookies?.get('__gtm_campaign_url') ?? null
  const campaignQueryParams = campaignUrl ? queryString?.parseUrl(campaignUrl)?.query : null

  return await newBackendClient.mutate<Mutation, MutationCreateInquiryRequestArgs>({
    variables: {
      createInquiryRequestInput: {
        fullName: values.fullName,
        hearAboutUs: values.hearAboutUs,
        make: values.make,
        model: values.model,
        phoneNumber: values.phoneNumber,
        windowUrl: window?.location?.href ?? null,
        queryParams: queryString.parse(window.location.search),
        campaignQueryParams: campaignQueryParams,
        source: values.source,
        symptoms: values.symptoms,
        year: values.vehicleYear,
        zip: String(values.zip),
        serviceUrgency: values.serviceUrgency,
        referrerSource: values.referrerSource,
        convertExperimentsData: getConvertExperimentsFromCookies(),
        extraFields: values.extraFields,
      },
    },
    mutation: gql`
      mutation createInquiryRequest($createInquiryRequestInput: CreateInquiryRequestInput!) {
        createInquiryRequest(createInquiryRequestInput: $createInquiryRequestInput) {
          id
          fullName
          phoneNumber
          queryParams
          source
          hearAboutUs
          serviceUrgency
          symptoms
          zip
          year
          make
          model
          referrerSource
        }
      }
    `,
  })
}
